import React from "react";
import classNames from "classnames";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Hero = ({ children, className, bgImage, style, ...props }) => {
  const showBgImage = bgImage ? { backgroundImage: `url(${bgImage})` } : null;

  const styles = {
    ...style,
    ...showBgImage,
  };

  return (
    <section
      className={classNames("c-hero", className)}
      style={styles}
      {...props}
    >
      {children}
    </section>
  );
};

const Inner = ({ children, className }) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      className={classNames("container-md", className, {
        "bg-dark-transparent": !breakpoints.md,
      })}
    >
      <div className="c-hero-inner row py-5 py-md-0 justify-content-md-between">
        {children}
      </div>
    </div>
  );
};

Hero.Inner = Inner;

const Content = ({ children, className }) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      className={classNames(
        "c-hero-content col-md-6 col-xl-5 py-md-5 py-lg-6 px-md-4 px-lg-5 order-md-first",
        {
          "bg-dark-transparent": breakpoints.md,
          [className]: className
        }
      )}
    >
     {children}
    </div>
  );
};

Hero.Content = Content;

const Title = ({children}) => {
  return (
    <h1
      className={classNames(
        "text-unset text-white text-center text-md-left mb-lg-4"
      )}
    >
      {children}
    </h1>
  );
}

Hero.Title = Title;

const Description = ({ children }) => {
  return <>{children}</>;
};

Hero.Description = Description;

const Image = ({ src, srcAlt, alt, className }) => {
  const breakpoints = useBreakpoint();
  return (
    <div className="c-hero-image col-12 col-md-6 d-flex align-self-center justify-content-center justify-content-md-end pb-4">
      <img
        src={breakpoints.md ? src : srcAlt ? srcAlt : src}
        alt={alt}
        className={classNames("img-fluid", className)}
        style={{ maxWidth: breakpoints.lg ? 288 : 250 }}
      />
    </div>
  );
};

Hero.Image = Image;

const CallToAction = ({ children }) => {
  return (
    <div className="text-center text-md-left pt-4">
      {children}
    </div>
  );
};

Hero.CallToAction = CallToAction;

export default Hero;
