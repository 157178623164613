import React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, Card, Carousel, Modal } from 'react-bootstrap'
import BlogFeed from '../components/blog-feed'
import CTA from '../components/cta'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Quote from '../components/quote'
import Hero from '../components/hero'
import SnapFingersIcon from '../icons/snap.svg'
import WebsiteDesignIcon from '../icons/website-design-multicolor.svg'
import ReputationIcon from '../icons/reputation-multicolor.svg'
import StrategyIcon from '../icons/strategy-multicolor.svg'
import StrengthIcon from '../icons/strength-multicolor.svg'
import IntegrationIcon from '../icons/integration-multicolor.svg'
import EvaluatingIcon from '../icons/evaluating-multicolor.svg'
import CustomerServiceIcon from '../icons/customer-service-help-multicolor.svg'
import patternHex from '../images/backrounds/pattern-hex-white.jpg'
import zendeskPartnerBadge from '../images/partners/zendesk-implementation-premier-partner-badge.png'
import ZendeskConnectedGraphic from '../images/partnerships-zendesk/zendesk-connected.svg'

const zendeskResources = [
  {
    title: 'Need to increase efficiency?',
    description: (
      <>
        <p>
          We designed our <Link to='/zendesk-efficiency-audits'>Efficiency Audits</Link> to help you make Zendesk more effective and productive.
        </p>
        <p>After the audit, we give you concrete, actionable steps that you can take to maximize your Zendesk investment.</p>
      </>
    ),
    href: '/zendesk-efficiency',
    ctaText: 'Read about Zendesk efficiency',
  },
  {
    title: 'Leverage our 4 Years of expertise to evaluate your Zendesk implementation.',
    description: <p>We have put together our essential checklist to benchmark your success and find places that you can improve.</p>,
    href: '/lp/zendesk-efficiency-checklist',
    ctaText: 'Download Zendesk efficiency checklist',
  },
  {
    title: 'Do you need Zendesk admins but don’t want to hire someone?',
    description: (
      <p>729 has experienced, certified professionals you can leverage by the hour every month to make improvements or help your agents and managers.</p>
    ),
    href: '/zendesk-admin',
    ctaText: 'Learn about Zendesk admin support',
  },
]

const zendeskOptions = [
  {
    option: 'Design',
    logo: <WebsiteDesignIcon height={65} />,
    text: 'Design',
  },
  {
    option: 'Efficiency & Optimization',
    logo: <ReputationIcon height={65} />,
    text: 'Efficiency & Optimization',
  },
  {
    option: 'Workflow Improvements',
    logo: <StrategyIcon height={65} />,
    text: 'Keep agents productive',
  },
  {
    option: 'Self Serve and Deflection',
    logo: <StrengthIcon height={65} />,
    text: 'Self Serve & Deflection',
  },
  {
    option: 'Integrations',
    logo: <IntegrationIcon height={65} />,
    text: 'Integrations',
  },
  {
    option: 'Reporting',
    logo: <EvaluatingIcon height={65} />,
    text: 'Reporting',
  },
  {
    option: 'Training',
    logo: <CustomerServiceIcon height={65} />,
    text: 'Training',
  },
]

const zendeskServices = [
  {
    icon: <WebsiteDesignIcon height={65} />,
    title: 'Design for Guide and Helpcenter',
    description: (
      <p>
        729 can help you design a powerful customer support center. With effective communication, quality branding and user segmentation, you can give your
        customers what they need in an easy to understand layout. Collect data on your most used articles and make sure your customers get the information they
        need. 729 specializes in clean, modern designed help centers for Zendesk.
      </p>
    ),
  },
  {
    icon: <ReputationIcon height={65} />,
    title: 'Efficiency and Optimization',
    description: (
      <>
        <p>
          729 will identify any areas where you can increase organization or automation. We will teach your managers to use Zendesk for your specific business
          need.
        </p>
        <ul>
          <li>Create the right Triggers, Automations, or Macros to help agents do their work efficiently</li>
          <li>How best to assign tickets to agents or groups, or automated responses</li>
          <li>Suggesting ways to organize tickets and improve the way agents interact with tickets and customers</li>
        </ul>
      </>
    ),
  },
  {
    icon: <StrategyIcon height={65} />,
    title: 'Workflow Improvement',
    description: (
      <>
        <p>
          Efficient workflows begin with the agent. Making sure they have what they need in one place to take care of the customer is mission critical. Improve
          your customer and agents’ experience with simple effective workflows that make sense.
        </p>
        <ul>
          <li>Improve agent response time</li>
          <li>Analyzing your current customer channels to determine the best ways to help you communicate</li>
          <li>Make sure tickets are routed to the right channel and people.</li>
          <li>Handle escalations efficiently</li>
        </ul>
      </>
    ),
  },
  {
    icon: <StrengthIcon height={65} />,
    title: 'Self Serve and Deflection',
    description: (
      <>
        <p>
          Make sure you only answer the right customer needs. 729 can help you make best use of the knowledge base and lower the time wasting of your agents
        </p>
        <ul>
          <li>Suggesting articles and posts which help to reduce the amount of new ticket requests</li>
          <li>Improving the type of help and resources available to your customers</li>
          <li>Helping to teach your agents/admins how to add new content as you grow</li>
          <li>Segmenting users and ticket types</li>
        </ul>
      </>
    ),
  },
  {
    icon: <IntegrationIcon height={65} />,
    title: 'Integrations',
    description: (
      <>
        <p>
          There are over 600 apps to help you wire up all your business tools. Let our experience help you find the best applications that give you the most
          benefit:
        </p>
        <ul>
          <li>Advising you on apps that streamline your workflows</li>
          <li>Using apps to integrate Zendesk with your other business tools like Slack, Jira, Salesforce etc...</li>
          <li>Improving team communication and escalations of tickets</li>
        </ul>
      </>
    ),
  },
  {
    icon: <EvaluatingIcon height={65} />,
    title: 'Reporting',
    description: (
      <>
        <p>Quality reporting is essential to seeing the performance of your team. 729 can help with:</p>
        <ul>
          <li>Custom group or team reporting</li>
          <li>Escalations reporting</li>
          <li>Exporting reports for custom SLA or presenting to customers or management</li>
        </ul>
        <p>We can help you visualize your important metrics and show the success of your customer service.</p>
      </>
    ),
  },
  {
    icon: <CustomerServiceIcon height={65} />,
    title: 'Training',
    description: (
      <>
        <h6 className='mb-0'>Admin Training</h6>
        <p>
          We can teach your managers how to quickly and easily create all the triggers, macros and other automations they need. We will show them step by step
          how to use the tool and make updates with confidence.
        </p>
        <h6 className='mb-0'>Agent Training</h6>
        <p>
          We will teach your agents the exact steps they need to take to handle any ticket and provide documentation for new agents so they can do their jobs on
          the first day!
        </p>
      </>
    ),
  },
]

const ZendeskAccordion = () => (
  <section>
    <div className='container py-6'>
      <div className='row '>
        <div className='col'>
          <h2 className='text-unset text-center'>Have more questions about our Zendesk services? Here’s a helpful FAQ.</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='accordion-729'>
            <div>
              <input className='ac-input' id='ac-1' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-1'>
                how do i know if i’m getting the most out of zendesk?
                <span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  We can run an <Link to='/zendesk-efficiency-audits/'> Efficiency Audit </Link> for you to see if you’re taking advantage of everything Zendesk
                  has to offer.
                </p>
              </div>
            </div>
            <div>
              <input className='ac-input' id='ac-2' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-2'>
                can i get a zendesk demo?<span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  Yes, we can help you with that. <Link to='/contact-us/'> Contact us here. </Link>
                </p>
              </div>
            </div>
            <div>
              <input className='ac-input' id='ac-3' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-3'>
                what if i need zendesk for multiple brands?<span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  Zendesk Multibrand is a great solution for you- you can set up multiple brands, link your websites, and manage your data all in one place.
                  <br />
                  <br />
                  <Link to='/zendesk-multibrand/'>Read more about how we set up Multibrand here.</Link>
                </p>
              </div>
            </div>
            <div>
              <input className='ac-input' id='ac-4' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-4'>
                why would i need help setting up zendesk?<span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  Zendesk has a lot of great customizations that make the initial setup somewhat complex if you’re new to Zendesk.
                  <br />
                  <br />
                  We want to make sure that you get the most out of all of its features, so we dive deep into your business’ needs and help you choose a path
                  that will get you the best Zendesk experience possible.
                </p>
              </div>
            </div>
            <div>
              <input className='ac-input' id='ac-5' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-5'>
                what if i just need general zendesk help? can you do a la carte services for me?<span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  Yes, we do a la carte Zendesk services. <Link to='/zendesk-everything/'>Read more here.</Link>
                </p>
              </div>
            </div>
            <div>
              <input className='ac-input' id='ac-6' name='accordion-1' type='checkbox' />
              <label className='ac-label' htmlFor='ac-6'>
                why should i work with a zendesk premier implementation partner?
                <span></span>
              </label>
              <div className='article ac-content'>
                <p className='m-0'>
                  We think you already know the answer to that one… you can make your Zendesk project easy on yourself and your team.
                  <br />
                  <br />
                  Or, you can work with a less-experienced partner and then re-do work later.
                  <br />
                  <br />
                  Worst of all, you could wing it and alienate your customers with a poorly thought-out Zendesk integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const ZendeskCarousel = () => (
  <Carousel className='bg-browser' variant='dark' fade controls={false} interval={null} indicatorLabels={['1', '2', '3', '4']}>
    <Carousel.Item>
      <a href='https://tidy729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
        <StaticImage imgClassName='screenshot' src='../images/zk-themes/tidy-screenshot.png' alt='First slide' layout='fullWidth' />
      </a>
      <Carousel.Caption style={{ position: 'unset' }}>
        <a href='https://tidy729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
          <h2 className='mb-4' style={{ fontSize: '26px', color: 'black' }}>
            Tidy
          </h2>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://snap729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
        <StaticImage imgClassName='screenshot' src='../images/zk-themes/snap-theme.jpeg' alt='Second slide' layout='fullWidth' />
      </a>
      <Carousel.Caption style={{ position: 'unset' }}>
        <a href='https://snap729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
          <h2 className='mb-4' style={{ fontSize: '26px', color: 'black' }}>
            Snap
          </h2>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://sprout729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
        <StaticImage imgClassName='screenshot' src='../images/zk-themes/sprout-screenshot.png' alt='Third slide' layout='fullWidth' />
      </a>
      <Carousel.Caption style={{ position: 'unset' }}>
        <a href='https://sprout729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
          <h2 className='mb-4' style={{ fontSize: '26px', color: 'black' }}>
            Sprout
          </h2>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://eminence729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
        <StaticImage imgClassName='screenshot' src='../images/zk-themes/eminence-screenshot.png' alt='Fourth slide' layout='fullWidth' />
      </a>
      <Carousel.Caption style={{ position: 'unset' }}>
        <a href='https://eminence729.zendesk.com/hc/en-us' target='_blank' rel='noreferrer'>
          <h2 className='mb-4' style={{ fontSize: '26px', color: 'black' }}>
            Eminence
          </h2>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
)

const ZendeskServicesModal = ({ showModal, handleModalClose }) => (
  <Modal className='services-modal border-0 p-0' show={showModal} onHide={handleModalClose} size='xl'>
    <Modal.Header closeButton className='border-0 bg-light' />
    <Modal.Body className='border-0 bg-light'>
      <div className='container'>
        <div className='row px-3'>
          <div className='col-12 text-center'>
            <h2 className='text-unset'>Make the Most of Zendesk</h2>
            <h6 className='mb-1 text-unset'>So you are thinking about making the move to Zendesk or just want to use it better. </h6>
            <p className='mb-3'>729 will help you set up and customize Zendesk, bring best practices and make the most of your investment.</p>
            <div className='row mb-5 px-3'>
              {zendeskOptions.map(({ text, logo }) => (
                <div className='col-6 col-md-3 col-lg-3'>
                  <Card className='shadow p-3' style={{ width: '130px', height: '140px' }}>
                    <div className='pb-1'>{logo}</div>
                    <Card.Body className='align-items-center card-body d-flex justify-content-center p-0'>
                      <h6 className='m-0' style={{ fontSize: '12px' }}>
                        {text}
                      </h6>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h2 className='text-center'>Our Zendesk services at a glance</h2>
          </div>
          {zendeskServices.map(({ icon, title, description }) => (
            <div className='col-12'>
              <div className='row mb-5'>
                <div className='col-3 d-flex justify-content-end pl-0'>{icon}</div>
                <div className='col-9 border-left-secondary border-secondary'>
                  <h3>{title}</h3>
                  {description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='row bg-primary'>
        <div className='col-12 text-center px-3 py-5'>
          <h2 className='text-white'>Whatever You Need to do with Zendesk, 729 Can Help!</h2>
          <p className='mb-3 text-white'>
            Take advantage of a free 1 hr call with 729 to discuss the best ways to transform all your software into exceptional business processes.
          </p>
          <CTA href='/contact-us' className='btn btn-primary-light' text='Contact Zendesk Master Partner 729 Solutions Today' />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 d-flex align-items-center justify-content-center p-5'>
          <button className='btn btn-matterhorn px-6 text-white' onClick={handleModalClose}>
            Close
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)

const Index = ({ location }) => {
  const [showModal, setShowModal] = useState(false)

  const handleModalClose = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Layout>
      <ZendeskServicesModal showModal={showModal} handleModalClose={handleModalClose} />
      <Seo
        pathName={location.pathname}
        title='Partnerships Zendesk'
        description='729 Solutions is a proud Zendesk Implementation Premier partner. Contact us to make the most of Zendesk with a migration, implementation or customization.'
      />
      <Hero bgImage={patternHex}>
        <Hero.Inner>
          <Hero.Image src={zendeskPartnerBadge} alt='Zendesk Implementation Premier Partner' />
          <Hero.Content>
            <Hero.Title>Zendesk</Hero.Title>
            <Hero.Description>
              <p className='text-white text-center text-md-left'>
                When it comes to Zendesk, we’ve done it all — data migrations, custom applications, integrations, and customizations.
              </p>
              <p className='text-white text-center text-md-left'>We’ll make your Zendesk experience seamless!</p>
              <p className='text-white text-center text-md-left'>
                With over 4 years and 120 successful Zendesk projects under our belt, we are proud to be named Zendesk’s AMER Partner of the Year!
              </p>
            </Hero.Description>
            <Hero.CallToAction>
              <CTA href='/contact-us' text='Get a custom theme now' className='btn btn-secondary-light' />
            </Hero.CallToAction>
          </Hero.Content>
        </Hero.Inner>
      </Hero>
      <section>
        <div className='container py-6'>
          <div className='row'>
            <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-start pb-5'>
              <ZendeskConnectedGraphic src='../images/partnerships-zendesk/zendesk-connected.svg' alt='Zendesk connected' width={238} />
            </div>
            <div className='col-12 order-md-first text-md-center'>
              <h2 className='mb-0'>Zendesk connected!</h2>
              <h3 className='text-unset'>Helping customers integrate Zendesk.</h3>
            </div>
            <div className='col-12 col-md-8 col-lg-9'>
              <p>
                Your customer support software is an essential piece of your business machine. But, if you want to build a high-performance race car, you need
                to connect all parts, so they work together effectively.
              </p>
              <p>
                729 Solutions specializes in integrating customer support with sales, HR, accounting, and all other business tools to optimize your team’s
                workflows. We use a mix of:
              </p>
              <ul>
                <li>Prebuilt marketplace apps</li>
                <li>Integration platforms </li>
                <li>Fully custom solutions to fit your needs.</li>
              </ul>
              <p className='font-weight-bold'>We will help you select the optimal way to make Zendesk fully connected.</p>
              <p>When you work with 729, you get an experienced partner helping you leverage all that Zendesk has to offer.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-primary'>
        <div className='container py-4 py-md-5'>
          <div className='row no-gutters'>
            <div className='align-items-start col-12 col-md-1 d-inline-flex justify-content-center justify-content-lg-start pb-4 pb-sm-0'>
              <SnapFingersIcon width={55} color='white' />
            </div>
            <div className='col-12 col-md-11 mb-lg-3 ml-lg-n3 pl-lg-0 pl-md-3'>
              <h3 className='text-unset text-white' style={{ lineHeight: '30px' }}>
                Take advantage of a free 1 hr call with 729 to discuss the best ways to transform all your software into exceptional business processes.
              </h3>
            </div>
            <div className='col-12'>
              <CTA className='btn btn-primary-light' href='/contact-us' text='Contact Zendesk Premier Partner 729 Solutions Today' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container py-6'>
          <div className='row'>
            <div className='col-12 text-center bg-light py-5' style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
              <h2>Make the most of Zendesk</h2>
              <div className='row px-5 mb-4 no-gutters'>
                {zendeskOptions.map(({ text, logo }) => (
                  <div className='col-6 col-md-3 col-lg mb-3'>
                    <Card className='m-auto shadow p-3' style={{ width: '130px', height: '140px' }}>
                      <div>{logo}</div>
                      <Card.Body className='align-items-center d-flex justify-content-center p-0'>
                        <h6 className='m-0' style={{ fontSize: '12px' }}>
                          {text}
                        </h6>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h6 className='mb-0 text-unset'>So you are thinking about making the move to Zendesk or just want to use it better. </h6>
                  <p>729 will help you set up and customize Zendesk, bring best practices and make the most of your investment.</p>
                  <Button onClick={handleShowModal} className='btn btn-primary'>
                    View our Zendesk Services
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='text-center'>Other Zendesk resources we offer</h2>
              <div className='row px-4 px-md-0'>
                {zendeskResources.map(({ title, description, href, ctaText }) => (
                  <div className='col-lg-4 mb-4'>
                    <Card className='m-auto shadow p-4 bg-light h-100'>
                      <Card.Body className='p-0 text-center d-flex flex-column justify-content-between'>
                        <h3 className='text-unset'>{title}</h3>
                        {description}
                        <CTA href={href} text={ctaText} className='btn btn-primary' />
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container py-6'>
          <div className='row mb-4 justify-content-center'>
            <div className='col-10 col-lg-6 py-2'>
              <ZendeskCarousel />
            </div>
            <div className='col-lg-6 text-center text-lg-left py-2'>
              <h2>We create Zendesk themes</h2>
              <p>
                Want something customized, but don’t have the budget for a full build from scratch? No problem! We have pre-built themes that can be used as a
                jumping-off point for your help center. We’ll swap out your logo, fonts, and colors to give you something different from your competitors, at a
                fraction of the cost of a fully custom theme.
              </p>
              <CTA href='/zendesk-themes' text='learn more' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>
      <section className='bg-dark'>
        <div className='container py-6'>
          <div className='row'>
            <div className='col py-5'>
              <Quote
                dark
                quote='729 ALLOWED US THE FLEXIBILITY TO SELF-IMPLEMENT, BUT WERE WITH US EVERY STEP OF THE WAY. THEY ANSWERED QUESTIONS, BRAINSTORMED, PROVIDED SOLUTIONS AND BEST PRACTICES FOR ANYTHING WE NEEDED. THEY ARE PERSONABLE, CARING, AND RELIABLE. I WOULD RECOMMEND WORKING WITH THEM, AND FEEL CONFIDENT EVERYONE WOULD FEEL THE SAME VALUE WE DID.'
                name='Kayla Fischer'
                company='Hirevue'
              />
            </div>
          </div>
        </div>
      </section>
      <ZendeskAccordion />
      <section className='bg-dark-transparent'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col'>
              <div className='text-center'>
                <h2 className='text-white'>Request A Quote</h2>
                <p className='text-white'>Reach out today to learn more about creating a Zendesk Help Center.</p>
                <CTA href='/contact-us' text='set up a free call' className='btn btn-secondary-light' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed containerClassname='py-6' />
    </Layout>
  )
}

export default Index
